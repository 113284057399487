import moment from 'moment-timezone';

const twoWeeksFromNow = moment().add(2, 'week').format('dddd, MMM Do, YYYY');

export const OFFICE_NAME = { category: 'Organization', value: 'Office Name', variable: '{Office_Name}', variableValue: 'Downtown' };
export const OFFICE_LOCATION = { category: 'Organization', value: 'Office Address', variable: '{Office_Location}', visibleVariable: '{Office_Address}', variableValue: '123 Main Street, Atlanta, GA 12345' };
export const APPOINTMENT_TYPE = { category: 'Appointment', value: 'Appointment Type', variable: '{Appointment_Type}', variableValue: 'Wellness Check' };
const FIRST_NAME = { category: 'Demographics', value: 'First Name', variable: '{First_Name}', variableValue: 'John' };
const LAST_NAME = { category: 'Demographics', value: 'Last Name', variable: '{Last_Name}', variableValue: 'Smith' };
const PREFERRED_NAME = { category: 'Demographics', value: 'Preferred Name', variable: '{Preferred_Name}', variableValue: 'Johnny' };
const APPOINTMENT_DATE_TIME = { category: 'Appointment', value: 'Appointment Date Time', variable: '{Appointment_Date_Time}', variableValue: `${twoWeeksFromNow} at 8:30 AM ET` };
const APPOINTMENT_DATE = { category: 'Appointment', value: 'Appointment Date', variable: '{Appointment_Date}', variableValue: `${twoWeeksFromNow}` };
const APPOINTMENT_TIME = { category: 'Appointment', value: 'Appointment Time', variable: '{Appointment_Time}', variableValue: '8:30 AM ET' };
const PROVIDER_NAME = { category: 'Organization', value: 'Provider Name', variable: '{Provider_Name}', variableValue: 'Dr. Smith' };
const REPLY_1_TO_CONFIRM = { category: 'CodifiedResponse', value: 'REPLY ‘1’ to CONFIRM', variable: '{REPLY_‘1’_to_CONFIRM}', variableValue: 'REPLY ‘1’ to CONFIRM' };
const REPLY_1_TO_CONSENT = { category: 'CodifiedResponse', value: 'REPLY ‘1’ to CONSENT', variable: '{REPLY_‘1’_to_CONSENT}', variableValue: 'REPLY ‘1’ to CONSENT' };
const REPLY_2_TO_CANCEL = { category: 'CodifiedResponse', value: 'REPLY ‘2’ to CANCEL', variable: '{REPLY_‘2’_to_CANCEL}', variableValue: 'REPLY ‘2’ to CANCEL' };
const SENDING_MEMBER_FIRST_NAME = { category: 'Member', value: 'Sending Member First Name', variable: '{Sending_Member_First_Name}', variableValue: 'Ashley' };
const SENDING_MEMBER_LAST_NAME = { category: 'Member', value: 'Sending Member Last Name', variable: '{Sending_Member_Last_Name}', variableValue: 'Jones' };
const ORGANIZATION_NAME = { category: 'Organization', value: 'Organization Name', variable: '{Organization_Name}', variableValue: '{organization_name}' };
const PRESCRIPTION_FOR_REFILL = { category: 'Prescription', value: 'Prescriptions for refill', variable: '{Prescriptions}', variableValue: 'Metformin and Lovenox' };
const REPLY_Y_TO_ACCEPT = { category: 'CodifiedResponse', value: 'REPLY ‘Y’ to accept', variable: '{REPLY_‘Y’_to_accept}', variableValue: 'REPLY ‘Y’ to accept' };
const REPLY_N_TO_DECLINE = { category: 'CodifiedResponse', value: 'REPLY ‘N’ to decline', variable: '{REPLY_‘N’_to_decline}', variableValue: 'REPLY ‘N’ to decline' };

export const APPOINTMENT_SCHEDULED_VARIABLE_OPTIONS = [
  FIRST_NAME,
  LAST_NAME,
  PREFERRED_NAME,
  APPOINTMENT_DATE_TIME,
  APPOINTMENT_DATE,
  APPOINTMENT_TIME,
  ORGANIZATION_NAME,
  PROVIDER_NAME,
];

export const APPOINTMENT_REMINDERS_VARIABLE_OPTIONS = [
  FIRST_NAME,
  LAST_NAME,
  PREFERRED_NAME,
  APPOINTMENT_DATE_TIME,
  APPOINTMENT_DATE,
  APPOINTMENT_TIME,
  ORGANIZATION_NAME,
  PROVIDER_NAME,
  REPLY_1_TO_CONFIRM,
  REPLY_2_TO_CANCEL,
];

export const HIPAA_CAMPAIGN_VARIABLE_OPTIONS = [
  FIRST_NAME,
  LAST_NAME,
  PREFERRED_NAME,
  ORGANIZATION_NAME,
  REPLY_Y_TO_ACCEPT,
  REPLY_N_TO_DECLINE,
];

export const TEMPLATE_MESSAGE_VARIABLE_OPTIONS = [
  FIRST_NAME,
  LAST_NAME,
  PREFERRED_NAME,
  APPOINTMENT_DATE_TIME,
  APPOINTMENT_DATE,
  APPOINTMENT_TIME,
  ORGANIZATION_NAME,
  PROVIDER_NAME,
  SENDING_MEMBER_FIRST_NAME,
  SENDING_MEMBER_LAST_NAME,
];

export const PRESCRIPTION_MESSAGE_VARIABLE_OPTIONS = [
  FIRST_NAME,
  LAST_NAME,
  PREFERRED_NAME,
  OFFICE_NAME,
  OFFICE_LOCATION,
  REPLY_1_TO_CONSENT,
  REPLY_2_TO_CANCEL,
  PRESCRIPTION_FOR_REFILL,
];

export const TEMPLATE_DEFAULT_TEXT = 'Hi {first_name}, This is {Organization_Name}. We want to remind you of your appointment with us on {appointment_date_time}. Once you arrived at our office please text Here and complete the following check-in forms. Thanks!';

export const AUTOMATED_MESSAGE_TEMPLATES = {
  REMINDER1: 'Hi {first_name}, we look forward to seeing you {appointment_date_time}. Text or call us if you have any questions. Thanks!',
  REMINDER2: 'Hi {first_name}, we look forward to seeing you {appointment_date_time}. Be sure to fill out your paperwork ahead of your appointment. Thanks!',
  SCHEDULED: 'Hi {first_name}, your appointment has been scheduled for {appointment_date_time}. Text or call us if you have any questions. Thanks!',
};

export const PRESCRIPTION_MESSAGE_TEMPLATE = 'Hi {preferred_name}, your prescriptions for {Prescriptions} have been brought to our attention. We need your consent to refill these prescriptions. Please {REPLY_‘1’_to_CONSENT} and we will notify you when your prescription has been sent. {REPLY_‘2’_to_CANCEL} to cancel your shipment.';

export const APPOINTMENT_SCHEDULED_OFFICE_INDEX = -1;
export const APPOINTMENT_REMINDERS_OFFICE_INDEX = -3;
export const APPOINTMENT_TYPE_INDEX = 3;

export const HIPAA_CAMPAIGN_TEMPLATE = `Hi ${FIRST_NAME.variable}, you can now text or call us at this number! Just like phone calls and voicemails, texting may not always be 100% secure depending on the mobile service you use. Knowing that, would you like us to communicate with you via text? ${REPLY_Y_TO_ACCEPT.variable} or ${REPLY_N_TO_DECLINE.variable}`;
